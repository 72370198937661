<template>
  <div>
    <!-- 顶部 -->
    <div
      style="
        height: 100px;
        border: 1px solid rgb(170, 170, 170);
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
      "
    >
      <div style="margin-left: 40px; margin-top: 20px">
        <div style="font-size: 24px; font-weight: 700; margin-bottom: 8px">
          2023年物理实验室巡查
        </div>
        <div style="font-size: 16px; color: gray; font-weight: 700">
          （任务时间：2023/09/01~2023/10/01）
        </div>
      </div>
      <div style="margin-top: 25px; margin-right: 40px">
        <el-button
          @click="
            () => {
              this.$router.back();
            }
          "
          style="height: 50px; width: 70px; text-align: center"
          >返回</el-button
        >
        <el-button
          v-if="status === 1"
          type="primary"
          style="margin-left: 20px; height: 50px; width: 120px"
          >提交巡查结果</el-button
        >
      </div>
    </div>
    <div
      style="display: flex; justify-content: space-between; margin-top: 20px"
    >
      <!-- 左部 -->
      <div
        style="
          width: 18%;
          height: 700px;
          border: 1px solid rgb(170, 170, 170);
          border-radius: 20px;
          text-align: center;
        "
      >
        <div style="margin: 20px 10px">
          <div
            v-for="(item, i) in labRoomList"
            :key="i"
            @click="handleSelectLabRoom(i)"
            style="
              height: 50px;
              line-height: 50px;
              border-radius: 10px;
              display: flex;
              justify-content: space-between;
              cursor: pointer;
              margin-bottom: 20px;
            "
            :style="{
              backgroundColor: item.isSelected ? 'rgb(128, 255, 255)' : '',
            }"
          >
            <div style="margin-left: 20px">{{ item.name }}</div>
            <div
              style="margin-right: 20px"
              :style="{
                color:
                  item.isSelected && item.isDone
                    ? '#00f'
                    : item.isSelected && !item.isDone
                    ? 'red'
                    : '#000',
              }"
            >
              {{ item.isDone ? "已巡查" : "待巡查" }}
            </div>
          </div>
        </div>
      </div>
      <!-- 右部 -->
      <div
        style="
          width: 78%;
          padding: 20px;
          height: 660px;
          border: 1px solid rgb(170, 170, 170);
          border-radius: 20px;
          overflow: hidden;
          overflow-y: scroll;
        "
      >
        <!-- 第一行 -->
        <div
          style="
            display: flex;
            justify-content: space-between;
            height: 50px;
            line-height: 50px;
          "
        >
          <div style="display: flex">
            <div
              style="
                display: flex;
                justify-content: space-between;
                width: 200px;
                height: 50px;
                background-color: rgb(220, 251, 172);
                border-radius: 15px;
              "
            >
              <div style="font-size: 18px; margin-left: 20px">通过数</div>
              <div
                style="
                  width: 70px;
                  height: 30px;
                  line-height: 30px;
                  background-color: #fff;
                  border-radius: 20px;
                  text-align: center;
                  margin-right: 20px;
                  margin-top: 10px;
                  font-size: 24px;
                  font-weight: 700;
                "
              >
                18
              </div>
            </div>
            <div
              style="
                display: flex;
                justify-content: space-between;
                width: 200px;
                height: 50px;
                background-color: rgb(252, 222, 182);
                border-radius: 15px;
                margin-left: 20px;
              "
            >
              <div style="font-size: 18px; margin-left: 20px">不通过数</div>
              <div
                style="
                  width: 70px;
                  height: 30px;
                  line-height: 30px;
                  background-color: #fff;
                  border-radius: 20px;
                  text-align: center;
                  margin-right: 20px;
                  margin-top: 10px;
                  font-size: 24px;
                  font-weight: 700;
                "
              >
                16
              </div>
            </div>
          </div>
          <div><el-button type="primary">保存</el-button></div>
        </div>
        <!-- 内容区域 -->
        <div>
          <div class="inspectItem">
            <div class="taskTextBox">
              <div class="taskTextBox-title">1、实验室前后们达到阿达撒打算</div>
              <div class="taskTextBox-content">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Expedita placeat delectus ducimus numquam pariatur similique
                quasi voluptatum exercitationem id? Odio provident tempora quas
                quis aperiam libero molestiae possimus dolorem fugiat. Nulla,
                voluptatum aperiam debitis laudantium dignissimos rem quidem
                quibusdam. Accusantium suscipit, tenetur veritatis quisquam
                inventore nesciunt corporis aspernatur cum dolorum ratione eum!
                Consequuntur eveniet accusamus aliquam laborum quo qui
                doloribus? Neque, suscipit eum. Qui sed iusto tenetur,
                recusandae natus nam at eos molestias. Fuga aliquid, dolorum
                ducimus iure expedita nesciunt omnis quam ut sit id sequi labore
                blanditiis. Suscipit soluta aliquam explicabo at? Osquam
                sapiente dolore commodi voluptatum veritatis laborum, dolorem
                animi tempore, dolorum blanditiis eos quo, necessitatibus sunt!
                Commodi ad at id fuga ea!
              </div>
            </div>
            <div class="resultBox">通过</div>
            <div class="pictureBox">图片</div>
          </div>
          <el-divider></el-divider>
        </div>
        <div>
          <div class="inspectItem">
            <div class="taskTextBox">
              <div class="taskTextBox-title">1、实验室前后们达到阿达撒打算</div>
              <div class="taskTextBox-content">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Expedita placeat delectus ducimus numquam pariatur similique
                quasi voluptatum exercitationem id? Odio provident tempora quas
                quis aperiam libero molestiae possimus dolorem fugiat. Nulla,
                voluptatum aperiam debitis laudantium dignissimos rem quidem
                quibusdam. Accusantium suscipit, tenetur veritatis quisquam
                inventore nesciunt corporis aspernatur cum dolorum ratione eum!
                Consequuntur eveniet accusamus aliquam laborum quo qui
                doloribus? Neque, suscipit eum. Qui sed iusto tenetur,
                recusandae natus nam at eos molestias. Fuga aliquid, dolorum
                ducimus iure expedita nesciunt omnis quam ut sit id sequi labore
                blanditiis. Suscipit soluta aliquam explicabo at? Osquam
                sapiente dolore commodi voluptatum veritatis laborum, dolorem
                animi tempore, dolorum blanditiis eos quo, necessitatibus sunt!
                Commodi ad at id fuga ea!
              </div>
            </div>
            <div class="resultBox red">不通过</div>
            <div class="pictureBox">图片</div>
          </div>
          <el-divider></el-divider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: "",
      labRoomList: [
        {
          name: "物理实验室",
          isDone: true,
          isSelected: true,
        },
        {
          name: "生物实验室",
          isDone: true,
          isSelected: false,
        },
        {
          name: "化学实验室",
          isDone: false,
          isSelected: false,
        },
      ],
    };
  },
  created() {
    let inspectInfo = JSON.parse(this.$route.query.inspectInfo);
    this.status = inspectInfo.status ? inspectInfo.status : 1;
  },
  methods: {
    handleSelectLabRoom(index) {
      this.labRoomList.forEach((item) => {
        item.isSelected = false;
      });
      this.labRoomList[index].isSelected = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.inspectItem {
  display: flex;
  padding: 30px 20px 0 20px;
}
.taskTextBox {
  width: 78%;
  .taskTextBox-title {
    font-weight: 700;
    font-size: 20px;
  }
  .taskTextBox-content {
    padding: 20px;
    text-indent: 2rem;
  }
}
.resultBox {
  width: 80px;
  height: 40px;
  line-height: 40px;
  background-color: rgb(202, 249, 130);
  text-align: center;
  border-radius: 10px;
  margin: auto 20px;
  &.red {
    background-color: rgb(236, 128, 141);
  }
}
.pictureBox {
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  margin: auto 20px;
  border: 1px solid #eee;
}
</style>
