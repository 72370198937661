var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"height":"100px","border":"1px solid rgb(170, 170, 170)","border-radius":"20px","display":"flex","justify-content":"space-between"}},[_vm._m(0),_c('div',{staticStyle:{"margin-top":"25px","margin-right":"40px"}},[_c('el-button',{staticStyle:{"height":"50px","width":"70px","text-align":"center"},on:{"click":() => {
            this.$router.back();
          }}},[_vm._v("返回")]),(_vm.status === 1)?_c('el-button',{staticStyle:{"margin-left":"20px","height":"50px","width":"120px"},attrs:{"type":"primary"}},[_vm._v("提交巡查结果")]):_vm._e()],1)]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","margin-top":"20px"}},[_c('div',{staticStyle:{"width":"18%","height":"700px","border":"1px solid rgb(170, 170, 170)","border-radius":"20px","text-align":"center"}},[_c('div',{staticStyle:{"margin":"20px 10px"}},_vm._l((_vm.labRoomList),function(item,i){return _c('div',{key:i,staticStyle:{"height":"50px","line-height":"50px","border-radius":"10px","display":"flex","justify-content":"space-between","cursor":"pointer","margin-bottom":"20px"},style:({
            backgroundColor: item.isSelected ? 'rgb(128, 255, 255)' : '',
          }),on:{"click":function($event){return _vm.handleSelectLabRoom(i)}}},[_c('div',{staticStyle:{"margin-left":"20px"}},[_vm._v(_vm._s(item.name))]),_c('div',{staticStyle:{"margin-right":"20px"},style:({
              color:
                item.isSelected && item.isDone
                  ? '#00f'
                  : item.isSelected && !item.isDone
                  ? 'red'
                  : '#000',
            })},[_vm._v(" "+_vm._s(item.isDone ? "已巡查" : "待巡查")+" ")])])}),0)]),_c('div',{staticStyle:{"width":"78%","padding":"20px","height":"660px","border":"1px solid rgb(170, 170, 170)","border-radius":"20px","overflow":"hidden","overflow-y":"scroll"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","height":"50px","line-height":"50px"}},[_vm._m(1),_c('div',[_c('el-button',{attrs:{"type":"primary"}},[_vm._v("保存")])],1)]),_c('div',[_vm._m(2),_c('el-divider')],1),_c('div',[_vm._m(3),_c('el-divider')],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-left":"40px","margin-top":"20px"}},[_c('div',{staticStyle:{"font-size":"24px","font-weight":"700","margin-bottom":"8px"}},[_vm._v(" 2023年物理实验室巡查 ")]),_c('div',{staticStyle:{"font-size":"16px","color":"gray","font-weight":"700"}},[_vm._v(" （任务时间：2023/09/01~2023/10/01） ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","width":"200px","height":"50px","background-color":"rgb(220, 251, 172)","border-radius":"15px"}},[_c('div',{staticStyle:{"font-size":"18px","margin-left":"20px"}},[_vm._v("通过数")]),_c('div',{staticStyle:{"width":"70px","height":"30px","line-height":"30px","background-color":"#fff","border-radius":"20px","text-align":"center","margin-right":"20px","margin-top":"10px","font-size":"24px","font-weight":"700"}},[_vm._v(" 18 ")])]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","width":"200px","height":"50px","background-color":"rgb(252, 222, 182)","border-radius":"15px","margin-left":"20px"}},[_c('div',{staticStyle:{"font-size":"18px","margin-left":"20px"}},[_vm._v("不通过数")]),_c('div',{staticStyle:{"width":"70px","height":"30px","line-height":"30px","background-color":"#fff","border-radius":"20px","text-align":"center","margin-right":"20px","margin-top":"10px","font-size":"24px","font-weight":"700"}},[_vm._v(" 16 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inspectItem"},[_c('div',{staticClass:"taskTextBox"},[_c('div',{staticClass:"taskTextBox-title"},[_vm._v("1、实验室前后们达到阿达撒打算")]),_c('div',{staticClass:"taskTextBox-content"},[_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita placeat delectus ducimus numquam pariatur similique quasi voluptatum exercitationem id? Odio provident tempora quas quis aperiam libero molestiae possimus dolorem fugiat. Nulla, voluptatum aperiam debitis laudantium dignissimos rem quidem quibusdam. Accusantium suscipit, tenetur veritatis quisquam inventore nesciunt corporis aspernatur cum dolorum ratione eum! Consequuntur eveniet accusamus aliquam laborum quo qui doloribus? Neque, suscipit eum. Qui sed iusto tenetur, recusandae natus nam at eos molestias. Fuga aliquid, dolorum ducimus iure expedita nesciunt omnis quam ut sit id sequi labore blanditiis. Suscipit soluta aliquam explicabo at? Osquam sapiente dolore commodi voluptatum veritatis laborum, dolorem animi tempore, dolorum blanditiis eos quo, necessitatibus sunt! Commodi ad at id fuga ea! ")])]),_c('div',{staticClass:"resultBox"},[_vm._v("通过")]),_c('div',{staticClass:"pictureBox"},[_vm._v("图片")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inspectItem"},[_c('div',{staticClass:"taskTextBox"},[_c('div',{staticClass:"taskTextBox-title"},[_vm._v("1、实验室前后们达到阿达撒打算")]),_c('div',{staticClass:"taskTextBox-content"},[_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita placeat delectus ducimus numquam pariatur similique quasi voluptatum exercitationem id? Odio provident tempora quas quis aperiam libero molestiae possimus dolorem fugiat. Nulla, voluptatum aperiam debitis laudantium dignissimos rem quidem quibusdam. Accusantium suscipit, tenetur veritatis quisquam inventore nesciunt corporis aspernatur cum dolorum ratione eum! Consequuntur eveniet accusamus aliquam laborum quo qui doloribus? Neque, suscipit eum. Qui sed iusto tenetur, recusandae natus nam at eos molestias. Fuga aliquid, dolorum ducimus iure expedita nesciunt omnis quam ut sit id sequi labore blanditiis. Suscipit soluta aliquam explicabo at? Osquam sapiente dolore commodi voluptatum veritatis laborum, dolorem animi tempore, dolorum blanditiis eos quo, necessitatibus sunt! Commodi ad at id fuga ea! ")])]),_c('div',{staticClass:"resultBox red"},[_vm._v("不通过")]),_c('div',{staticClass:"pictureBox"},[_vm._v("图片")])])
}]

export { render, staticRenderFns }